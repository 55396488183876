'use strict';

var base = require('base/product/base');
var detail = require('base/product/detail');
var detailUpdateAddToCartEnableDisableOtherElements = detail.methods.updateAddToCartEnableDisableOtherElements;

var PhotoSwipeDefault = require('photoswipe/dist/photoswipe-ui-default.js');
var PhotoSwipe = require('photoswipe/dist/photoswipe.js');
const { Splide } = require('@splidejs/splide/dist/js/splide.cjs.js');

localStorage.setItem('productIdViewed', '.product[data-pid="' + $('.product-id').first().text() + '"]');

if (window.dataLayer) {
    $('body').trigger('gtm:pdp:view', {
        productId: $('.product-id').first().text()
    });
}

let mainGallery;

function initGallery() {
    let gallery = document.querySelector('.pdp-media__gallery');
    if (gallery) {
        mainGallery = new Splide('.pdp-media__gallery', {
            type: 'loop',
            pagination: false,
            lazyLoad: 'nearby'
        });

        let thumbs = new Splide('.pdp-media__thumbs', {
            isNavigation: true,
            pagination: false,
            arrows: false,
            fixedWidth: 100,
            gap: 16
        });

        thumbs.mount();
        mainGallery.sync(thumbs);
        mainGallery.mount();
    }
}

$(document).ready(function () {
    $('body').trigger('collect:pdp:view', {
        productId: $('.product-id').first().text()
    });
});

function initPhotoSwipeFromDOM(gallerySelector) {
    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    var parseThumbnailElements = function parseThumbnailElements(el) {
        var thumbElements = el.querySelectorAll('.splide__slide:not(.splide__slide--clone)'),
            numNodes = thumbElements.length,
            items = [],
            figureEl,
            linkEl,
            size,
            item;

        for (var i = 0; i < numNodes; i++) {
            figureEl = thumbElements[i]; // <figure> element
            // include only element nodes

            if (figureEl.nodeType !== 1) {
                continue;
            }

            linkEl = figureEl.children[0]; // <a> element

            if (linkEl.tagName && linkEl.tagName !== 'A') {
                continue;
            } else {
                size = linkEl.getAttribute('data-size').split('x'); // create slide object

                item = {
                    src: linkEl.getAttribute('href'),
                    w: parseInt(size[0], 10),
                    h: parseInt(size[1], 10)
                };
    
                if (figureEl.children.length > 1) {
                    // <figcaption> content
                    item.title = figureEl.children[1].innerHTML;
                }
    
                if (linkEl.children.length > 0) {
                    // <img> thumbnail element, retrieving thumbnail url
                    item.msrc = linkEl.children[0].getAttribute('src');
                }
            }

            item.el = figureEl; // save link to element for getThumbBoundsFn

            items.push(item);
        }

        return items;
    }; // find nearest parent element


    var closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    }; // triggers when user clicks on thumbnail


    var onThumbnailsClick = function onThumbnailsClick(e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;
        var eTarget = e.target || e.srcElement; // find root element of slide

        var clickedListItem = closest(eTarget, function (el) {
            return el.tagName && el.tagName.toUpperCase() === 'LI';
        });

        if (eTarget.tagName === 'VIDEO') {
            return;
        }

        if (!clickedListItem) {
            return;
        } // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute


        var clickedGallery = clickedListItem.parentNode,
            childNodes = clickedListItem.parentNode.querySelectorAll('.splide__slide:not(.splide__slide--clone)'),
            numChildNodes = childNodes.length,
            nodeIndex = 0,
            index;

        for (var i = 0; i < numChildNodes; i++) {
            if (childNodes[i].nodeType !== 1) {
                continue;
            }

            if (childNodes[i] === clickedListItem) {
                index = nodeIndex;
                break;
            }

            nodeIndex++;
        }

        if (index >= 0) {
            // open PhotoSwipe if valid index found
            openPhotoSwipe(index, clickedGallery);
        }

        return false;
    }; // parse picture index and gallery index from URL (#&pid=1&gid=2)


    var photoswipeParseHash = function photoswipeParseHash() {
        var hash = window.location.hash.substring(1),
            params = {};

        if (hash.length < 5) {
            return params;
        }

        var vars = hash.split('&');

        for (var i = 0; i < vars.length; i++) {
            if (!vars[i]) {
                continue;
            }

            var pair = vars[i].split('=');

            if (pair.length < 2) {
                continue;
            }

            params[pair[0]] = pair[1];
        }

        if (params.gid) {
            params.gid = parseInt(params.gid, 10);
        }

        return params;
    };

    var openPhotoSwipe = function openPhotoSwipe(index, galleryElement, disableAnimation, fromURL) {
        var pswpElement = document.querySelectorAll('.pswp')[0],
            gallery,
            options,
            items;
        items = parseThumbnailElements(galleryElement); // #################### 3/4 define photoswipe options (if needed) #################### 
        // https://photoswipe.com/documentation/options.html //

        options = {
            history: false,
            bgOpacity: 0.9,

            /* 'showHideOpacity' uncomment this If dimensions of your small thumbnail don't match dimensions of large image */
            showHideOpacity: true,
            // Buttons/elements
            closeEl: true,
            captionEl: true,
            fullscreenEl: true,
            zoomEl: true,
            shareEl: false,
            counterEl: false,
            arrowEl: true,
            preloaderEl: true,
            // define gallery index (for URL)
            galleryUID: galleryElement.getAttribute('data-pswp-uid'),
            // getDoubleTapZoom: function(isMouseClick, item) {
            // 	return 4;
            // },
            getThumbBoundsFn: function getThumbBoundsFn(index) {
                // See Options -> getThumbBoundsFn section of documentation for more info
                var thumbnail = items[index].el.getElementsByTagName('img')[0],
                    // find thumbnail
                    pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
                    rect = thumbnail.getBoundingClientRect();
                return {
                    x: rect.left,
                    y: rect.top + pageYScroll,
                    w: rect.width
                };
            }
        }; // PhotoSwipe opened from URL

        if (fromURL) {
            if (options.galleryPIDs) {
                // parse real index when custom PIDs are used
                // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
                for (var j = 0; j < items.length; j++) {
                    if (items[j].pid == index) {
                        options.index = j;
                        break;
                    }
                }
            } else {
                // in URL indexes start from 1
                options.index = parseInt(index, 10) - 1;
            }
        } else {
            options.index = parseInt(index, 10);
        } // exit if index not found


        if (isNaN(options.index)) {
            return;
        }

        if (disableAnimation) {
            options.showAnimationDuration = 0;
        } // Pass data to PhotoSwipe and initialize it

        gallery = new PhotoSwipe(pswpElement, PhotoSwipeDefault, items, options);
        gallery.init();
        /* ########### PART 4 - EXTRA CODE  ########### */

        /* EXTRA CODE (NOT FROM photoswipe CORE) - 
        1/2. UPDATE GALLERY POSITION TO THE 'CURRENT' ZOOM_IN IMAGE (BETTER UI) */
        // photoswipe event: Gallery unbinds events
        // (triggers before closing animation)

        gallery.listen('unbindEvents', function () {
            // The index of the current photoswipe slide
            var getCurrentIndex = gallery.getCurrentIndex(); // Update position of the slider

            mainGallery.go(getCurrentIndex);
        });
    }; // loop through all gallery elements and bind events


    var galleryElements = document.querySelectorAll(gallerySelector);

    for (var i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
    } // Parse URL and open gallery if it contains #&pid=3&gid=1


    var hashData = photoswipeParseHash();

    if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
    }
}; // execute above function


$(document).ready(function () {
    if (typeof PhotoSwipe !== 'undefined') {
        initPhotoSwipeFromDOM('.gallery');
    }
});

function isDesktop() {
    return $(window).width() >= 1220;
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement(modalId) {
    if ($('.' + modalId).length !== 0) {
        $('.' + modalId).remove();
    }
    var htmlString = '<!-- Modal -->' +
        '<div class="modal fade ' + modalId + '" id="' + modalId + '" tabindex="-1" role="dialog" aria-labelledby="modalTitle">' +
        '<div class="modal-dialog modal-dialog-centered" role="document">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <i class="fa-regular fa-xmark"></i>' +
        '        <span class="sr-only">Close</span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                .data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId
            };
        }).toArray();

    return JSON.stringify(options);
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10)
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && ($('.set-items').length || $($el).closest('.product-detail').find('.quantity-select').length > 0)) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        quantitySelected = $('.quantity-select');
    }
    return quantitySelected;
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString = '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' + data.maxBonusItems + '"' +
        'data-UUID="' + data.uuid + '"' +
        'data-pliUUID="' + data.pliUUID + '"' +
        'data-addToCartUrl="' + data.addToCartUrl + '"' +
        'data-pageStart="0"' +
        'data-pageSize="' + data.pageSize + '"' +
        'data-moreURL="' + data.showProductsUrlRuleBased + '"' +
        'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' + data.labels.selectprods + '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
            var parsedHtml = parseHtml(response.renderedTemplate);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .enter-message').text(response.enterDialogMessage);
            $('#chooseBonusProductModal .modal-header .close .sr-only').text(response.closeButtonText);
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Call Cart-AddCartBag that will show the current basket with the lasted added product
 * @param {element} addToCartBtn - Element that triggered the add to cart click event
 */
function addToBag(addToCartBtn) {
    var addToBagUrl = $(addToCartBtn).data('addtobag');
    var pid = $('.product-detail').data('pid');
    if (addToBagUrl) {
        $.ajax({
            url: addToBagUrl + '?pid=' + pid,
            method: 'GET',
            success: function (data) {
                $('#addToBagModal').find('.add-to-bag').empty().append(data);
                $('#addToBagModal').addClass('p-0');
                $('#addToBagModal').modal('show');
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}
/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {element} addToCartBtn - Element that triggered the add to cart click event
 */
function handlePostCartAdd(response, addToCartBtn) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">' +
            response.message +
            '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }

    if (!response.error && !isDesktop()) {
        addToBag(addToCartBtn);
    } else if (isDesktop()) {
        $('.minicart').trigger('minicart:show');
        $('.minicart .popover').addClass('minicart__custom-popover');
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
function processSwatchValues(attr, $productContainer, msgs) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchButton = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.parent().addClass('selected');
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
        } else {
            $attrValue.parent().removeClass('selected');
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');

        if (!attrValue.selectable) {
            $attrValue.parent().attr('disabled', true);
        } else {
            $attrValue.parent().removeAttr('disabled');
        }
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 */
function updateAttrs(attrs, $productContainer, msgs) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer, msgs);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
* Process TrueFit button with updated product id and color swatches
*
* @param {Object} response - Response from Ajax call
*/
function updateTrueFit(response) {
    const trueFitButton = document.querySelector('.tfc-fitrec-product');

    let color;
    response.product.variationAttributes.forEach((attribute) => {
        if (attribute.swatchable) {
            attribute.values.forEach((option) => {
                if (option.selected) {
                    color = option.value;
                }
            });
        }
    });

    if (trueFitButton) {
        trueFitButton.setAttribute('data-styleid', response.product.id);
        if (color) {
            trueFitButton.setAttribute('data-colorid', color);
        }
    }
}

/**
 * Dynamically creates swipper carousel from response containing images
 * @param {Object[]} imgs - Array of large product images,along with related information
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function createCarousel(imgs, $productContainer) {
    /* Main media container */
    var mediaContainer = $productContainer.find('.pdp-media');
    /* Main carousel */
    var carousel = $productContainer.find('.pdp-media__gallery');
    /* Thumbnail carousel */
    var carouselThumbnail = $productContainer.find('.pdp-media__thumbs');

    if (imgs.length > 1 && imgs[0] && imgs[0].url) {
        /* Create main content: next, prev, ul, ol */
        $(carousel).empty().append('<div class="splide__track"><ul class="splide__list gallery p-0"></ul></div>');
        $(carouselThumbnail).empty().append('<div class="splide__track"><ul class="splide__list"></ul></div>');

        var isQuickView = $(carousel).closest('.product-quickview');
        /* Create images */
        for (var i = 0; i < imgs.length; i++) {
            if (isQuickView.length > 0) {
                $('<li class="splide__slide"><img src="' + imgs[i].url + '" class="d-block w-100 img-fluid" alt="' + imgs[i].alt + ' image number ' + parseInt(imgs[i].index, 10) + '" itemprop="image" /></li>').appendTo($(carousel).find('.splide__list'));
            } else if (i === 0) {
                $(`<li class="splide__slide">
                    <a href="${imgs[i].url}" data-size="1100x1200">
                        <img srcset="${imgs[i].mobile} 600w, ${imgs[i].url} 1200w" sizes="(max-width: 1220px) 90vw, 45vw" src="${imgs[i].url}" class="d-block w-100 img-fluid" alt="${imgs[i].alt} image number ${parseInt(imgs[i].index, 10)}" itemprop="image" />
                    </a>
                </li>`).appendTo($(carousel).find('.splide__list'));
            } else {
                $(`<li class="splide__slide">
                    <a href="${imgs[i].url}" data-size="1100x1200">
                        <img data-splide-lazy-srcset="${imgs[i].mobile} 600w, ${imgs[i].url} 1200w"
                        sizes="(max-width: 1220px) 90vw, 45vw"
                        data-splide-lazy="${imgs[i].url}" class="d-block w-100 img-fluid" alt="${imgs[i].alt} image number ${parseInt(imgs[i].index, 10)}" itemprop="image" />
                    </a>
                </li>`).appendTo($(carousel).find('.splide__list'));
            }

            $(`<li class="splide__slide"><img src="${imgs[i].thumbnail}" class="d-block w-100 img-fluid" alt="${imgs[i].alt} image number ${parseInt(imgs[i].index, 10)}" itemprop="image" /></li>`).appendTo($(carouselThumbnail).find('.splide__list'));
        }

        initGallery();

        if (typeof PhotoSwipe !== 'undefined') {
            initPhotoSwipeFromDOM('.gallery');
        }
    } else if (imgs[0] && imgs[0].url) {
        $(mediaContainer).empty();
        $(`<img src="${imgs[0].url}" class="d-block img-fluid" alt="${imgs[0].alt}" itemprop="image" />`).appendTo($(mediaContainer));
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;

    if (response.product.readyToOrder) {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<li><span class="d-block mb-4 text-danger">' + message + '</span></li>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' +
                    attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images['hi-res'];
    createCarousel(primaryImageUrls, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length ?
            $('.prices .price', $productContainer) :
            $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product,
            $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} optionsHtml - Ajax response optionsHtml from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(optionsHtml, $productContainer) {
    // Update options
    $productContainer.find('.product-options').empty().html(optionsHtml);
}

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        var optionsHtml = quantities.map(function (quantity) {
            var selected = quantity.selected ? ' selected ' : '';
            return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                selected + '>' + quantity.value + '</option>';
        }).join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

function updateQuantity(selectedQty, $productContainer) {
    if ($productContainer.parent('.bonus-product-item').length <= 0) {
        $productContainer.find('quantity-js').attr('data-url', selectedQty.url);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', {
            url: selectedValueUrl,
            container: $productContainer
        });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.optionsHtml, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                updateQuantity(data.product.selectedQty, $productContainer);
                updateTrueFit(data);
                $('body').trigger('product:afterAttributeSelect', {
                    data: data,
                    container: $productContainer
                });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Add to cart on click event. This can only be triggered if the product has stock.
 */
function addToCart() {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var addToCartBtn = $(this);

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = getPidValue($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
        }

        addToCartUrl = getAddToCartUrl();
        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: getChildProducts(),
            quantity: getQuantitySelected($(this))
        };

        if (!$('.bundle-item').length) {
            form.options = getOptions($productContainer);
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data, addToCartBtn);
                    $('body').trigger('product:afterAddToCart', data);
                    if (window.dataLayer) {
                        $('body').trigger('gtm:pdp:add', {
                            data: data,
                            form: form
                        });
                    }
                    $('body').trigger('collect:cart:addToCart', data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL);
                },
                error: function (error) {
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * Registering on change event on quantity selector. If store has been selected, exist function,
 * otherwise proceed to update attributes.
 */
function availability() {
    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var searchPID = $(this).closest('.product-detail').attr('data-pid');
        var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';

        if ($(selectorPrefix + ' .selected-store-with-inventory').is(':visible')) {
            return;
        }

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.product-quickview');
        }

        if ($('.bundle-items', $productContainer).length === 0) {
            attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer);
        }
    });
}

function colorAttribute() {
    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();
        $.spinner().start();

        if ($(this).attr('disabled') || $(this).hasClass('selected')) {
            $.spinner().stop();
            return;
        }
        $('.color .color-val').text($(this).data('label'));
        $('.size-chart__wrapper .true-fit__button').attr('data-colorid', $(this).data('attr-value'));
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        attributeSelect($(this).attr('data-url'), $productContainer);
    });
}

function selectAttribute() {
    $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
        e.preventDefault();
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        attributeSelect(e.currentTarget.value, $productContainer);
    });
}

function selectVariantAttribute() {
    $(document).on('click', '.product-attr-js', function (e) {
        e.preventDefault();
        $.spinner().start();

        if ($(this).hasClass('selected')) {
            $.spinner().stop();
            return;
        }

        $(this).parent().parent().find('.product-attr-js')
            .removeClass('selected');

        $(this).addClass('selected');
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }
        attributeSelect($(this).attr('value'), $productContainer);
    });
}


function sizeChartTemplate() {
    $(document).on('click', '.size-chart__js', function (e) {
        e.preventDefault();
        $.spinner().start();
        $.get($(this).attr('href'), function (data) {
            getModalHtmlElement('sizeChartModal');
            let newHeading = document.createElement('h2');
            newHeading.classList.add('h5', 'font-weight-bold');
            newHeading.setAttribute('id', 'modalTitle');
            let title = document.createTextNode(data.modalTitle);
            newHeading.appendChild(title);
            let headingDescription = document.createElement('p');
            let description = document.createTextNode(data.modalDescription);
            headingDescription.classList.add('size-chart__header-description');
            headingDescription.appendChild(description);

            let container = document.createElement('div');
            container.classList.add('size-chart__header-container');
            container.appendChild(newHeading);
            container.appendChild(headingDescription);
            $('#sizeChartModal').find('.modal-body').empty().append(data.content);
            $('#sizeChartModal').find('.modal-header').prepend(container);
            $('#sizeChartModal').modal('show');
            $.spinner().stop();
        });
    });
}

function inseamGuideTemplate() {
    $(document).on('click', '.inseam-guide__js', function (e) {
        e.preventDefault();
        $.spinner().start();
        $.get($(this).attr('href'), function (data) {
            getModalHtmlElement('inseamGuideModal');
            $('#inseamGuideModal').find('.modal-body').empty().append(data.content);
            $('#inseamGuideModal').modal('show');
            $.spinner().stop();
        });
    });
}

/**
 * Enable/disable Buy Now buttons based on product availability
 * @param {boolean} enableOrDisable - true or false
 */
function updateAddToCartEnableDisableOtherElements(enableOrDisable) {
    detailUpdateAddToCartEnableDisableOtherElements.call(this, enableOrDisable);
    $('.salesforce-buynow-element').attr('disabled', enableOrDisable);
}

function updateAttributesAndDetails() {
    $('body').on('product:statusUpdate', function (e, data) {
        var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

        if (data.longDescription) {
            $productContainer.find('.description-and-detail #productLongDescription .card-body')
                .empty()
                .html(data.longDescription);
        }
    });
}

var exportDetails = $.extend({}, base, detail, {
    updateAttributesAndDetails: updateAttributesAndDetails,
    availability: availability,
    selectAttribute: selectAttribute,
    addToCart: addToCart,
    selectVariantAttribute: selectVariantAttribute,
    colorAttribute: colorAttribute,
    sizeChartTemplate: sizeChartTemplate,
    inseamGuideTemplate: inseamGuideTemplate
});

exportDetails.methods.updateAddToCartEnableDisableOtherElements = updateAddToCartEnableDisableOtherElements;

exportDetails.initPhotoSwipeFromDOM = initPhotoSwipeFromDOM;
exportDetails.initGallery = initGallery;
module.exports = exportDetails;
